/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

export default function LandingPage() {
    
  return (
    <>
      <Navbar />
      <Footer />
    </>
  );
}
